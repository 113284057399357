<template>
  <v-card>
    <v-toolbar flat>
    <v-toolbar-title>Configurazione</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tabs">
          <v-tab>Caricamento configurazioni</v-tab> 
          <v-tab>Caricamento file programma</v-tab>
          <v-tab>Confronto impostazioni</v-tab>
          <v-tab>Albero impostazioni</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <carica-configurazioni />
      </v-tab-item>
      <v-tab-item>
        <carica-moduli />
      </v-tab-item>
      <v-tab-item>
        <confronto-impostazioni />
      </v-tab-item>
      <v-tab-item>
        <albero-impostazioni />
      </v-tab-item>          
    </v-tabs-items>
  </v-card>
</template>

<script>
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import CaricaConfigurazioni from './elementi/caricaConfigurazioni.vue'
  import CaricaModuli from './elementi/caricaModuli.vue'
  import ConfrontoImpostazioni from './elementi/confrontoImpostazioni.vue'
  import AlberoImpostazioni from './elementi/alberoImpostazioni'

  export default {
    components: {
      CaricaConfigurazioni,
      CaricaModuli,
      ConfrontoImpostazioni,
      AlberoImpostazioni
    },
    mixins: [verificaLicenza],
    data () {
      return {
        tabs: null
      }
    },
    async mounted() {
      await this.verificaModuliLocali()
    },
    methods: {
    }
  }
</script>
