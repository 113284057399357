<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Configurazioni</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        label="Cerca impostazioni"
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        class="mt-3"
      ></v-text-field>
      <v-btn class="ml-5" color="success" :disabled="!daSalvare" @click.stop="onSalvaModifiche">Salva modifiche</v-btn>
    </v-toolbar>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card-title>
          <v-btn small title="Crea un elemento di primo livello" @click="creaElementoRoot">Crea elemento root</v-btn>
        </v-card-title>
        <v-card-text>
          <v-treeview
            :items="items"
            :search="search"
            :open.sync="open"
            dense
            hoverable
            activatable
            :open-on-click="true"
            :return-object="true"
            transition
            @update:active="onItemSelected"
          >
          <template v-slot:prepend="{ item }">
            <v-icon small @click="editItem(item)" title="Modifica l'elemento">mdi-pencil</v-icon>
          </template>
            <template v-slot:append="{ item }">
              <v-btn v-if="!item.children || item.children.length === 0" icon @click.stop="deleteItem(item)" title="Cancella l'elemento"><v-icon small>mdi-delete</v-icon></v-btn>
              <v-btn icon @click.stop="onNewItem(item)" title="Aggiungi un elemento figlio" ><v-icon small>mdi-plus-circle-outline</v-icon></v-btn>
            </template>          
          </v-treeview>
        </v-card-text>
      </v-col>
      <v-col v-if="!blocco || !blocco.valori">
        <v-card
          class="d-flex align-center mb-6"
          flat
          height="200"
          tile
        >
          <div class="pa-2 text-h5">Scegli la configurazione da modificare</div>
        </v-card>        
      </v-col>
      <v-col v-else>
        <v-card-title>{{ blocco.name }}</v-card-title>
        <v-card-subtitle>{{ blocco.descrizione }}</v-card-subtitle>
        <v-card-text>
          <v-tabs v-model="tabs">
            <v-tab v-for="(item, vdx) in blocco.valori" :key="vdx">
              {{ item.label }}
              <v-btn icon @click="tabDeleteItem(blocco, vdx)"><v-icon small >mdi-delete</v-icon></v-btn>
            </v-tab> 
            <v-btn icon title="Aggiungi un elemento" class="ml-3 mt-2" @click="tabsAddElement(blocco)"><v-icon>mdi-table-column-plus-after</v-icon></v-btn>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="(item, vdx) in blocco.valori" :key="vdx">
              <v-row>
                <v-col><v-select v-model="item.type" :items="tipiItem" dense solo label="Tipo campo" clearable class="mt-3"/></v-col>
                <v-col></v-col>
              </v-row>
              <template v-if="item.type==='Testo'">
                <v-row>
                  <v-col cols="8"><v-text-field v-model="item.label" label="label" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.descrizione" label="descrizione" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.hint" label="suggerimento" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.variabile" label="variabile" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.default" label="default" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8">
                    <v-row dense>
                      <v-col cols="2"><v-switch v-model="item.numerico" inset dense label="numerico" class="ml-5 mt-0"/></v-col>
                      <v-col cols="3"><v-text-field v-if="item.numerico" v-model="item.decimals" label="decimali" hide-details clearable outlined dense></v-text-field></v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="item.type==='Switch'">
                <v-row>
                  <v-col cols="8"><v-text-field v-model="item.label" label="label" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.descrizione" label="descrizione" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.hint" label="suggerimento" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.variabile" label="variabile" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-switch v-model="item.default" inset dense label="default" class="ml-5 mt-0"/></v-col>
                </v-row>                               
              </template>
              <template v-else-if="item.type==='Multiselect'">
                <v-row>
                  <v-col cols="8"><v-text-field v-model="item.label" label="label" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.descrizione" label="descrizione" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.hint" label="suggerimento" hide-details clearable outlined dense></v-text-field></v-col>
                  <v-col cols="8"><v-text-field v-model="item.variabile" label="variabile" hide-details clearable outlined dense></v-text-field></v-col>
                  <!-- <v-col cols="8"><v-text-field v-model="item.default" label="default" hide-details clearable outlined dense></v-text-field></v-col> -->
                  <v-col cols="8">
                    <v-card>
                      <v-card-title>Valori possibili<v-btn icon title="Aggiungi un elemento" class="ml-3" @click="multiselectAddElement(item)"><v-icon>mdi-playlist-plus</v-icon></v-btn></v-card-title>
                      <v-data-table :headers="headersMultiselect" :items="item.source" dense hide-default-header hide-default-footer>
                        <template v-slot:[`item.actions`]="{ item, index }">
                          <v-icon small class="mr-2" @click="multiselectEditItem(item)">mdi-pencil</v-icon>
                          <v-icon small @click="multiselectDeleteItem(item, index, vdx)">mdi-delete</v-icon>
                        </template>                                    
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col cols="8">
                    <v-select v-model="item.default" :items="item.source" item-text="descrizione" item-value="codice" return-object dense outlined label="Default" clearable/>
                  </v-col>
                </v-row>                               
              </template>              
              <template v-else>
                Tipo {{item.type}} non gestito: procedere manualmente
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogEditItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Modifica elemento</v-card-title>
        <v-card-text>
          <v-container dense>
            <v-row><v-col><v-text-field v-model="editedItem.name" label="Nome" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row>
            <v-row><v-col><v-text-field v-model="editedItem.descrizione" label="Descrizione" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row>
            <!-- <v-row><v-col><v-text-field v-model="editedItem.id" label="Id" readonly hide-details></v-text-field></v-col></v-row> -->
            <v-row><v-col><v-select v-model="editedItem.moduli" :items="itemsModuli" multiple label="Moduli" clearable clear-icon="mdi-close-circle-outline" persistent-hint hint="Se vuoto vale per tutti, altrimenti solo selezionato"></v-select></v-col></v-row>
            <v-row><v-col><v-select v-model="editedItem.ruolo" :items="itemsRuoli" label="Livello di accesso" clearable clear-icon="mdi-close-circle-outline"></v-select></v-col></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="editAnnulla">Annulla</v-btn> -->
          <v-btn color="blue darken-1" text @click="editSalva">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMultiselectEditItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Modifica elemento</v-card-title>
        <v-card-text>
          <v-container dense>
            <v-row><v-col><v-text-field v-model="editedItem.codice" label="Codice" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row>
            <v-row><v-col><v-text-field v-model="editedItem.descrizione" label="Descrizione" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editMultiselectSalva">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
  </v-card>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import tabelle from '@/services/tabelleRemoteService'

  export default {  
    props: [ 'menu' ],
    data () {
      return {
        tabs: null,
        // sezione: null,
        updateTrigger: 1,
        search: null,
        caseSensitive: false,
        open: [],
        itemsScroller: [],
          // TODO: La versione definitiva dovrebbe essere come le impostazioni di VSCode --> selezionando treeview 
          //        porta sulla voce corretta ma si può scrollare anche sulle voci precedenti
          //        La versione attuale di virtual-scroller non permette di spostarsi da codice --> non si riesce a puntare dove visualizzare
          //        Workaround: Alla selezione su treeview cambia l'elenco degli items visualizzabili
        blocco: {},
        bloccoRamo: {},
        bloccoValori: [],
        valoriConfigurazione: {},
        struttura: null,
        daSalvare: false,
        defaultRootItem: {
          id: '',
          name: '',
          ruolo: '',
          route: '',
          children: [],
          valori: []
        },
        editedItem: {
          id: '',
          name: '',
          ruolo: '',
          moduli: []
        },
        defaultBlocco: {
				  label: '',
				  descrizione: '',
				  hint: '',
				  variabile: '',
				  default: '',
				  numerico: false,
				  decimals: 0,
          source: []
        },
        dialogEditItem: false,
        dialogMultiselectEditItem: false,
        itemsModuliDefault: ['ottici', 'tabacchi', 'cashmatic', 'lotti', 'distintaBase', 'fatture'], // Todo: leggere da moduli licenza ? --> Creare elenco apposito su impostazioni
        itemsModuli: [],
        itemsRuoli: ['manager', 'administrator', 'ubisell'],
        tipiItemDefault: ['Testo', 'Multiselect', 'Switch', 'RepartiFiscali', 'AliquoteIva'],
        tipiItem: [],
        headersMultiselect: [
          {
            text: 'Codice',
            align: 'start',
            sortable: false,
            value: 'codice'
          },
          { text: 'Descrizione', value: 'descrizione' },
          { text: 'Azioni', value: 'actions', sortable: false }
        ],
        nomeTabellaStruttura: 'struttura_configurazione'
      }
    },
    beforeMount() {
      window.addEventListener("beforeunload", this.preventNav)
    },
    beforeDestroy() {
      window.removeEventListener("beforeunload", this.preventNav);
    },
    beforeRouteLeave (to, from, next) {
      if (this.modificheNonSalvate) {
        const answer = window.confirm('Ci sono delle modifiche non salvate, confermi uscita ?')
        if (!answer) return false
      }
      next()
    },
    async mounted() {
      const strutturaConfigurazione = await tabelle.getTabella(this.nomeTabellaStruttura)
      this.struttura = strutturaConfigurazione.struttura
      this.itemsModuli = strutturaConfigurazione.itemsModuli || this.itemsModuliDefault
      this.tipiItem = strutturaConfigurazione.tipiItem || this.tipiItemDefault
      this.fixRecurse(this.struttura)
    },
    computed: {
      items() {
        return this.struttura || []
      },
      modificheNonSalvate() {
        return this.daSalvare
      }
    },
    methods: {
      tabsAddElement(blocco) {
        blocco.valori = blocco.valori || []
        let valore = JSON.parse(JSON.stringify(this.defaultBlocco))
        valore.label = `Nuovo elemento ${blocco.valori.length + 1}`
        valore.type = 'Testo'
        blocco.valori.push(valore)
        this.daSalvare = true
      },
      tabDeleteItem(blocco, vdx) {
        const answer = window.confirm('Confermi cancellazione elemento ?')
        if (!answer) return false
        blocco.valori.splice(vdx, 1)
        this.daSalvare = true
      },
      multiselectAddElement(item) {
        item.source = item.source || []
        const idx = item.source.length > 0 ? _.maxBy(item.source, 'codice').codice : 0
        item.source.push({
          codice: idx + 1,
          descrizione: `Nuovo ${idx + 1}`
        })
        this.daSalvare = true
      },
      multiselectEditItem(item) {
        this.editedItem = item
        this.dialogMultiselectEditItem = true
        this.daSalvare = true
      },
      multiselectDeleteItem(el, index, vdx) {
        console.log(this.blocco.valori[vdx].source, vdx, index)
        this.blocco.valori[vdx].source.splice(index, 1)
        this.daSalvare = true
      }, 
      onChangeModel(item) {
        item.valueField = item.model
        this.daSalvare = true
      },
      editItem(item) {
        this.editedItem = item
        this.dialogEditItem = true
      },
      editSalva() {
        this.dialogEditItem = false
        this.daSalvare = true
      },
      editMultiselectSalva() {
        this.dialogMultiselectEditItem = false
        this.daSalvare = true    
      },
      editAnnulla() {
         this.dialogEditItem = false
         this.dialogMultiselectEditItem = false
      },
      creaElementoRoot() {
        let el = JSON.parse(JSON.stringify(this.defaultRootItem))
        el.name = `Nuovo Elemento ${this.struttura.length + 1}`
        el.id = uuidv4()
        this.struttura.push(el)
        this.daSalvare = true
      },
      fixRecurse(items, parentId) { // inserisce parentId se non esiste
        for (let el of items) {
          if (!el.parentId) {
            el.parentId = parentId
          }
          if (el.children) {
            this.fixRecurse(el.children, el.id)
          }
        }
      },      
      findRecurse(items, id) {
        var item
        for (let el of items) {
          if (el.id === id) {
            item = el
          } else {
            if (el.children) {
              item = this.findRecurse(el.children, id)
            }
          }
          if (item) {
            break
          }
        }
        return item
      },
      deleteItem(item) {
        const answer = window.confirm('Confermi cancellazione elemento ?')
        if (!answer) return false
        if (!item.parentId) {
          let elx = this.struttura.findIndex(x => x.id === item.id)
          if (elx !== -1) {
            this.struttura.splice(elx, 1)
          }
        } else {
          let parent = this.findRecurse(this.struttura, item.parentId)
          let elx = (parent.children).findIndex(x => x.id === item.id)
          console.log(elx)
          parent.children.splice(elx, 1)
        }
        this.daSalvare = true
      },
      onNewItem(item) {
        let el = JSON.parse(JSON.stringify(this.defaultRootItem))
        el.name = `Nuovo Elemento ${item.children.length + 1}` // TODO: Prendere il valore dell'ultimo, altrimenti errore se cancello un elemento e ricreo
        el.id =  el.id = uuidv4() // sanitize(el.name)
        el.parentId = item.id
        item.children.push(el)
        this.daSalvare = true
      },
      preventNav(event) {
        if (!this.modificheNonSalvate) return
        event.preventDefault()
        event.returnValue = ""
      },  
      async onSalvaModifiche() {
        let strutturaConfigurazione = await tabelle.getTabella(this.nomeTabellaStruttura)
        strutturaConfigurazione.struttura = this.struttura
        await tabelle.modificaTabella(strutturaConfigurazione)
        this.daSalvare = false
      },
      async onItemSelected(elementi) {
        let el = elementi && elementi.length > 0 ? elementi[0] : {}
        if (!_.isEmpty(el)) {
          this.tabs = 0
          this.blocco = el
        } else {
          this.blocco = null
        }
      }
    }
  }
</script>
